import imageCheckmark from 'static/images/social-media-tools/checkmark.svg'
import imageFacebook from 'static/images/social-media-tools/facebook.svg'
import imageGoogle from 'static/images/social-media-tools/google.svg'
import imageHashtag from 'static/images/social-media-tools/hashtag.svg'
import imageInstagram from 'static/images/social-media-tools/instagram.svg'
import imageLinkedIn from 'static/images/social-media-tools/linkedin.svg'
import imagePencil from 'static/images/social-media-tools/pencil.svg'
import imagePinterest from 'static/images/social-media-tools/pinterest.svg'
import imageReddit from 'static/images/social-media-tools/reddit.svg'
import imageSmile from 'static/images/social-media-tools/smile.svg'
import imageText from 'static/images/social-media-tools/text.svg'
import imageTikTok from 'static/images/social-media-tools/tiktok.svg'
import imageTwitter from 'static/images/social-media-tools/twitter.svg'
import imageUser from 'static/images/social-media-tools/user.svg'
import imageYouTube from 'static/images/social-media-tools/youtube.svg'

import imageBackgroundIconCheckmark from 'static/images/social-media-tools/background_icon_checkmark.svg'
import imageBackgroundIconHashtag from 'static/images/social-media-tools/background_icon_hashtag.svg'
import imageBackgroundIconPencil from 'static/images/social-media-tools/background_icon_pencil.svg'
import imageBackgroundIconSmile from 'static/images/social-media-tools/background_icon_smile.svg'
import imageBackgroundIconText from 'static/images/social-media-tools/background_icon_text.svg'
import imageBackgroundIconUser from 'static/images/social-media-tools/background_icon_user.svg'

import imageEndingIconEllipse from 'static/images/social-media-tools/ending_icon_ellipse.svg'
import imageEndingIconHashtag from 'static/images/social-media-tools/ending_icon_hashtag.svg'
import imageEndingIconPencil from 'static/images/social-media-tools/ending_icon_pencil.svg'
import imageEndingIconUser from 'static/images/social-media-tools/ending_icon_user.svg'

export const FREE_TOOLS_UI = {
  default: {
    imageStart: imagePencil,
    imageBackground: imageBackgroundIconPencil,
    imageEnding: imageEndingIconEllipse,
  },
  'internal-caption-generator': {
    imageStart: imagePencil,
    imageBackground: imageBackgroundIconPencil,
    imageEnding: imageEndingIconEllipse,
  },
  'internal-google-business-caption-generator': {
    imageStart: imageGoogle,
    imageBackground: imageBackgroundIconPencil,
    imageEnding: imageEndingIconPencil,
  },
  'internal-instagram-caption-generator': {
    imageStart: imageInstagram,
    imageBackground: imageBackgroundIconPencil,
    imageEnding: imageEndingIconPencil,
  },
  'internal-linkedin-caption-generator': {
    imageStart: imageLinkedIn,
    imageBackground: imageBackgroundIconPencil,
    imageEnding: imageEndingIconPencil,
  },
  'internal-facebook-caption-generator': {
    imageStart: imageFacebook,
    imageBackground: imageBackgroundIconPencil,
    imageEnding: imageEndingIconPencil,
  },
  'internal-pinterest-caption-generator': {
    imageStart: imagePinterest,
    imageBackground: imageBackgroundIconPencil,
    imageEnding: imageEndingIconPencil,
  },
  'internal-reddit-caption-generator': {
    imageStart: imageReddit,
    imageBackground: imageBackgroundIconPencil,
    imageEnding: imageEndingIconPencil,
  },
  'internal-tiktok-caption-generator': {
    imageStart: imageTikTok,
    imageBackground: imageBackgroundIconPencil,
    imageEnding: imageEndingIconPencil,
  },
  'internal-x-caption-generator': {
    imageStart: imageTwitter,
    imageBackground: imageBackgroundIconPencil,
    imageEnding: imageEndingIconPencil,
  },
  'internal-youtube-caption-generator': {
    imageStart: imageYouTube,
    imageBackground: imageBackgroundIconPencil,
    imageEnding: imageEndingIconPencil,
  },
  'internal-hashtag-generator': {
    imageStart: imageHashtag,
    imageBackground: imageBackgroundIconHashtag,
    imageEnding: imageEndingIconEllipse,
  },
  'internal-instagram-hashtag-generator': {
    imageStart: imageInstagram,
    imageBackground: imageBackgroundIconHashtag,
    imageEnding: imageEndingIconHashtag,
  },
  'internal-facebook-hashtag-generator': {
    imageStart: imageFacebook,
    imageBackground: imageBackgroundIconHashtag,
    imageEnding: imageEndingIconHashtag,
  },
  'internal-x-hashtag-generator': {
    imageStart: imageTwitter,
    imageBackground: imageBackgroundIconHashtag,
    imageEnding: imageEndingIconHashtag,
  },
  'internal-tiktok-hashtag-generator': {
    imageStart: imageTikTok,
    imageBackground: imageBackgroundIconHashtag,
    imageEnding: imageEndingIconHashtag,
  },
  'internal-linkedin-hashtag-generator': {
    imageStart: imageLinkedIn,
    imageBackground: imageBackgroundIconHashtag,
    imageEnding: imageEndingIconHashtag,
  },
  'internal-username-generator': {
    imageStart: imageUser,
    imageBackground: imageBackgroundIconUser,
    imageEnding: imageEndingIconEllipse,
  },
  'instagram-instagram-username-generator': {
    imageStart: imageInstagram,
    imageBackground: imageBackgroundIconUser,
    imageEnding: imageEndingIconUser,
  },
  'internal-x-username-generator': {
    imageStart: imageTwitter,
    imageBackground: imageBackgroundIconUser,
    imageEnding: imageEndingIconUser,
  },
  'internal-tiktok-username-generator': {
    imageStart: imageTikTok,
    imageBackground: imageBackgroundIconUser,
    imageEnding: imageEndingIconUser,
  },
  'internal-text-to-emoji': {
    imageStart: imageText,
    imageBackground: imageBackgroundIconText,
    imageEnding: imageEndingIconEllipse,
  },
  'internal-emoji-to-text': {
    imageStart: imageSmile,
    imageBackground: imageBackgroundIconSmile,
    imageEnding: imageEndingIconEllipse,
  },
  'social-media-glossary': {
    imageStart: imageCheckmark,
    imageBackground: imageBackgroundIconCheckmark,
    imageEnding: imageEndingIconEllipse,
  },
  'similarity-checker': {
    imageStart: imageCheckmark,
    imageBackground: imageBackgroundIconCheckmark,
    imageEnding: imageEndingIconEllipse,
  },
  'qr-code-generator': {
    imageStart: imageCheckmark,
    imageBackground: imageBackgroundIconCheckmark,
    imageEnding: imageEndingIconEllipse,
  },
  'brand-voice-generator': {
    imageStart: imageCheckmark,
    imageBackground: imageBackgroundIconCheckmark,
    imageEnding: imageEndingIconEllipse,
  },
}
